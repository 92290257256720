<template>
  <section>
    <language-dropdown v-if="!showCardOnly" />

    <el-card v-loading="loading"
      :element-loading-text="loadingText"
      :class="wrapperClasses"
      shadow="always">
      <el-button v-if="showCloseBtn"
        type="text"
        class="btn-close"
        data-testid="authcard-close-button"
        @click="closeBtnClick">
        <i class="far fa-arrow-left" />
      </el-button>

      <img src="@/assets/images/megaport-logos/Megaport-Logo-Web-72dpi-RGB.png"
        :alt="$t('images.megaport-logo')"
        width="240"
        height="69">
      <h2 v-if="headingText">
        {{ headingText }}
      </h2>
      <slot name="description" />
      <el-button v-if="btnText"
        :type="btnPrimary ? 'primary' : ''"
        :class="btnClasses"
        :disabled="btnDisabled"
        @click="btnClick">
        {{ $t(btnText) }}
      </el-button>
    </el-card>
  </section>
</template>

<script>
import LanguageDropdown from '@/components/ui-components/LanguageDropdown.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'AuthCard',

  components: {
    'language-dropdown': LanguageDropdown,
  },

  props: {
    // Main heading under MP logo 
    headingText: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Loading..',
    },
    // Footer button text
    btnText: {
      type: String,
      required: false,
      default: '',
    },
    // Classes for card wrapper
    wrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
    // Classes for footer button
    btnClasses: {
      type: String,
      required: false,
      default: '',
    },
    // Make footer button primary
    btnPrimary: {
      type: Boolean,
      required: false,
      default: true,
    },
    btnDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Show close button on top right of card
    showCloseBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCardOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['btn-click', 'close'],

  computed: {
    ...mapState('Auth', ['loggedIn']),
  },

  methods: {
    ...mapActions('Auth', ['logout']),
    async closeBtnClick() {
      if (this.showCardOnly) {
        this.$emit('close')
        return
      }

      if (this.loggedIn) {
        await this.logout()
      }

      this.$router.push('/login', () => { /* empty */ })
    },
    /**
     * Button click handler
     */
    btnClick() {
      this.$emit('btn-click')
    },
  },
}
</script>

<style lang="scss" scoped>
section {
  outline: none;
}

.el-card {
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: min-content;
  min-width: 420px;
  margin: auto;
  margin-bottom: 3rem;
  text-align: center;
  border-radius: 10px;

  ::v-deep .el-card__body {
    position: relative;
    padding: 30px;
  }

  .btn-close {
    position: absolute;
    top: 12px;
    left: 12px;
    padding: 0;

    &:hover {
      color: var(--color-megaport-red);
    }
  }

  img {
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    width: 95%;
    min-width: 300px;
    justify-content: center;
  }
}
</style>
