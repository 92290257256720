const showModalAlert = (message, title, type = 'default', isHtml = false) => {
  let confirmButtonClass
  switch (type) {
    case 'default':
      confirmButtonClass = ''
      break
    case 'success':
      confirmButtonClass = 'el-button--success'
      break
    case 'info':
      confirmButtonClass = 'el-button--info'
      break
    case 'warning':
      confirmButtonClass = 'el-button--warning'
      break
    case 'error':
      confirmButtonClass = 'el-button--danger'
      break

    default:
      console.error('Invalid alert type')
      return
  }

  const config = {
    confirmButtonText: window.mpApp.$t('general.ok'),
    confirmButtonClass: confirmButtonClass,
    type: type,
    customClass: `${type}-alert`,
    modal: false,
    dangerouslyUseHTMLString: isHtml,
  }
  window.mpApp.$alert(message, title, config)
    .then(() => {
      // empty function is intentional
    })
    .catch(() => {
      // empty function is intentional
    })
}

export {
  showModalAlert,
}
