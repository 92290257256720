<template>
  <div class="language-dropdown">
    <el-menu mode="horizontal">
      <el-submenu index="language"
        data-nav="language">
        <template #title>
          <span class="sr-only">{{ $t('general.languages') }}</span>
          <mu-mega-icon icon="Globe"
            class="dropdown-image" />
        </template>
        <el-menu-item v-for="(language, key) in languages"
          :key="key"
          index="language"
          @click="changeLanguage(language)">
          <span :class="{ 'color-primary': language.iso === $i18n.locale }">
            {{ language.name }}
          </span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { setActiveLanguage, languageSelections } from '@/i18n/SetupI18n.js'

export default {
  name: 'LanguageDropdown',

  data() {
    return {
      languages: languageSelections,
    }
  },

  methods: {
    /**
     * Change app language
     * @param {string} lang Language code
     */
    changeLanguage(lang) {
      setActiveLanguage(lang)
    },
  },
}
</script>

<style lang="scss" scoped>
.language-dropdown {
  position: absolute;
  top: 0;
  right: 0;

  .dropdown-image {
    height: 22px;
    width: 22px;
    color: var(--color-primary);
  }

  ::v-deep .el-menu.el-menu--horizontal {
    background: transparent;
    border-bottom: none;

    .el-submenu {
      .el-submenu__title:hover {
        background: transparent;
      }

      &.is-active {
        .el-submenu__title {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
