<template>
  <div>
    <!-- Successful -->
    <auth-card v-if="status === G_AUTH_VERIFICATION_SUCCESSFUL"
      :heading-text="$t('general.success')"
      :btn-text="$t('authentication.login-megaport')"
      :show-close-btn="true"
      @btn-click="login">
      <template #description>
        <p>{{ $t('authentication.email-verified') }}</p>
      </template>
    </auth-card>
    <!-- Unsuccessful -->
    <auth-card v-if="status === G_AUTH_VERIFICATION_UNSUCCESSFUL"
      :heading-text="$t('authentication.invalid-link')"
      :btn-text="$t('authentication.verify-email-button')"
      :show-close-btn="true"
      @btn-click="resendEmail">
      <template #description>
        <p>{{ $t('authentication.request-link') }}</p>
      </template>
    </auth-card>
    <!-- Already verified -->
    <auth-card v-if="status === G_AUTH_VERIFICATION_VERIFIED"
      :heading-text="$t('authentication.verified-account')"
      :btn-text="$t('authentication.login-megaport')"
      :show-close-btn="true"
      @btn-click="login">
      <template #description>
        <p>{{ $t('authentication.verified-email') }}</p>
      </template>
    </auth-card>
    <!-- Server error -->
    <auth-card v-if="status === G_AUTH_VERIFICATION_ERROR"
      :heading-text="$t('authentication.unexpected-error-dialog-title')"
      :btn-text="$t('authentication.login-megaport')"
      :show-close-btn="true"
      @btn-click="$router.push('login')">
      <template #description>
        <p>{{ $t('authentication.unexpected-error-dialog-desc') }}</p>
      </template>
    </auth-card>
    <!-- Loading dialog -->
    <el-dialog :visible.sync="showProcessingDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :center="true"
      top="4vh"
      width="fit-content">
      <div class="text-align-center">
        <img src="@/assets/loader.gif"
          :alt="$t('images.loading-logo')">
        <h4>{{ $t('authentication.confirming') }}</h4>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { showModalAlert } from '@/components/authentication/AuthHelpers.js'
import { mapActions, mapMutations } from 'vuex'
import integrations from '@/third-party-integrations/integrations.js'
import AuthCard from '@/components/ui-components/AuthCard.vue'
import sdk from '@megaport/api-sdk'

export default {
  name: 'SignupConfirmEmail',

  components: {
    'auth-card': AuthCard,
  },

  props: {
    isSSO: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showProcessingDialog: true,
      status: '',
    }
  },

  computed: {
    /**
     * Get userId from route query param
     */
    userId() {
      return this.$route.query.uid || ''
    },
    /**
     * Get confirmation code from route query param
     */
    confirmationCode() {
      return this.$route.query.c || ''
    },
    /**
     * Get email from route query param
     */
    email() {
      return this.$route.query.e || ''
    },
    /**
     * Get client ID from route query param
     */
    clientId() {
      return this.$route.query.cid || ''
    },
  },

  /**
   * Confirm user on page load
   */
  mounted() {
    this.confirmUser()
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    ...mapActions('Auth', ['start']),

    login() {
      return this.isSSO ? this.federate() : this.$router.push('login')
    },

    async federate() {
      try {
        const response = await sdk.instance.federate(this.email)
        if (response.status === 'SSO') {
          integrations.awsAmplify
            .federatedSignIn({ customProvider: response.idp.name })
            .catch(() => this.notifyBad({ title: this.$t('general.unexpected-error') }))
          return
        }

        this.$router.push('/login/username')
      } catch (failObj) {
        if (failObj.handled) return

        console.error(failObj)

        let errorMessage = ''
        let errorLevel = 'error'

        if (failObj.status === 500) {
          errorMessage = this.$t('general.unexpected-error')
        } else if (failObj.status === 401 || failObj.data?.message) {
          errorMessage = failObj.data?.message
        } 

        showModalAlert(errorMessage, this.$t('authentication.failed'), errorLevel)
      }
    },
    /**
     * Check URL params and show appropriate card based on status
     */
    confirmUser() {
      // Show unsuccessful if any params are missing
      if (!this.userId || !this.confirmationCode || !this.email || !this.clientId) {
        this.status = this.G_AUTH_VERIFICATION_UNSUCCESSFUL
        this.showProcessingDialog = false
        return
      }

      sdk.instance
        .confirmSignup(this.userId, this.confirmationCode, this.email, this.clientId)
        .then(() => {
          localStorage.setItem('_username', this.email || '')
          this.status = this.G_AUTH_VERIFICATION_SUCCESSFUL
        })
        .catch(err => {
          if (err.status === 403) {
            this.status = this.G_AUTH_VERIFICATION_VERIFIED
          } else if (err.status === 500) {
            this.status = this.G_AUTH_VERIFICATION_ERROR
          } else {
            this.status = this.G_AUTH_VERIFICATION_UNSUCCESSFUL
            console.error(err)
          }
        })
        .finally(() => {
          this.showProcessingDialog = false
        })
    },
    /**
     * Retrigger confirmation email
     */
    resendEmail() {
      const email = this.email || localStorage.getItem('_username')

      sdk.instance.retriggerConfirmationEmail(email)
        .then(() => {
          this.notifyGood({
            title: this.$t('authentication.verify-email-resent-successful'),
          })
        })
        .catch(error => {
          if (!error.handled) {
            this.notifyBad({
              title: this.$t('authentication.verify-email-resent-unsuccessful'),
            })
          }
        })
    },
  },
}
</script>
